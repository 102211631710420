const CATEGORIES = [
 
  {
    key: '1',
    text: '11Khái niệm cơ bản về Tin học',
    value: '1'
  },
  {
    key: '2',
    text: 'Hệ điều hành',
    value: '2'
  },
  {
    key: '3',
    text: 'MS Word',
    value: '3'
  },
  {
    key: '4',
    text: 'MS Excel',
    value: '4'
  },
  {
    key: '5',
    text: 'Internet',
    value: '5'
  },
  
];

export default CATEGORIES;
